/*******************************
         Site Overrides
*******************************/

.ui.modal, .ui.mini.modal {
  background: none;
  box-shadow: none;
  width: 100%;
  background-color: white;
  height: 100vh;  
  margin-top: 0;
  
  @media only screen and (min-width: 1024px) {
    height: initial;
  }

  > .header:not(.ui) {
    font-size: 2rem;
    padding: 3.4rem 3.2rem;
    border-bottom: 0;

    @media only screen and (max-width: 1024px) {
      font-size: 1.8rem;
    }
  }
  > .content {
    padding: 0 3.2rem 2rem;
  }
  > .actions {
    background: @white;
    border-top: 0;
    padding: 4rem 3.2rem 2.4rem;

    @media only screen and (max-width: 1024px) {
      background: none;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  > .close {
    top: 2.2rem;
    right: 2.2rem;
    color: @white;
  }
}

.ui.modals.dimmer.active {
  opacity: 0 !important;
  background: rgba(0, 38, 61, 0.92);
  padding: 0;

  &:last-of-type {
    opacity: 1 !important;
  }
}

ui.modal {
  margin-top: 0;
}

