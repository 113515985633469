/*******************************
         Site Overrides
*******************************/

.ui.input{
  min-width: 30rem;

  > input {
    background: #f5f7f9;
    border-radius: 0;
  }

  input::placeholder {
    color: @placeholderColorInput;
  }
}

.ui.disabled.input {
  opacity: @disabledOpacity;
  
  > input {
    background: @disabledColor;
  } 
}