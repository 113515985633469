/*******************************
         Site Overrides
*******************************/

.ui.checkbox.error label {
  color: @red;
}

.ui.checkbox.disabled {
  pointer-events: none;
}

.ui.checkbox {
  input[type="checkbox"] { 
    width: 2.4rem;
    height: 2.4rem;
  }

  label {    
    &:hover  {
      &:before {
        background: #f5f7f9;
      }
    }

    &:before, &:after {
      width: 2.4rem;
      height: 2.4rem;
      border: none;
      top: -0.3rem;
    }

    &:before {
      background: #f5f7f9;
    }

    &:after {
      background: #ffcc00;
      padding: 0.3rem;
      font-size: 2rem;
    }
  }
}
