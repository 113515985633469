/*******************************
         Site Overrides
*******************************/

.ui.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0; 
  background-color: #00395d !important;
  color: #ffffff !important;
  border-radius: 0;

  &:hover {
    background-color: #33617d !important;
    color: #ffffff !important;
  }
}

.ui.button.primary {
  background-color: #ffcc00 !important;
  color: #00395d !important;

  &:hover {
    background-color: #ffd633 !important;
    color: #00395d !important;
  }
}

.ui.button.close.reversed {
  background-color: #ffffff !important;
  color: #00395d !important;
}

.ui.button:disabled {
  opacity: 0.5 !important;
  background-color: #ebeff2;
}